<template>
	<div style="background: #f9f9fb;height: 100vh;">
		<div style="padding: 60px 20px 20px 20px;position: relative;">
			<div style="position: absolute;top: 0;z-index: 1;left: 0;">
				<el-image style="height: calc(100vh - 550px);width: 100%;" :src="img" :fit="'cover'"></el-image>
			</div>
			<div style="z-index: 999;position: relative;">
				<!-- 头像 -->
				<div style="display: flex;align-items: center;">
					<el-image style="width: 40px;height: 40px;border-radius: 40px;" v-if="avatarurl!==null" :src="avatarurl"></el-image>
					<el-image style="width: 40px;height: 40px;" v-else :src="require('@/assets/img/head.png')"></el-image>
					<div style="padding-left: 10px;color: #fff;">{{userInfo==null ? '暂未登录' : userInfo.nikename}}</div>
				</div>
				
				<div style="padding-top: 20px;color: #fff;">可用积分：{{userInfo==null ? '暂无' : userInfo.userscore}}</div>
				
				<div style="border-radius: 10px;background: #fff;width: 100%;display: flex;flex-wrap: wrap;margin-top: 20px;padding: 20px;box-shadow: 0 0 14px -2px rgb(97 97 97 / 20%);">
					<div style="width: 25%;">
						<div style="display: flex;align-items: center;justify-content: center;">
							<el-image style="width: 30px;height: 30px;" :src="require('@/assets/img/icon/information.png')"></el-image>
						</div>
						<div style="padding-top: 10px;text-align: center;" @click="jump(4)">基本信息</div>
					</div>
					
					<div style="width: 25%;">
						<div style="display: flex;align-items: center;justify-content: center;">
							<el-image style="width: 30px;height: 30px;" :src="require('@/assets/img/icon/order.png')"></el-image>
						</div>
						<div style="padding-top: 10px;text-align: center;" @click="jump(2)">我的订单</div>
					</div>
					
					<div style="width: 25%;">
						<div style="display: flex;align-items: center;justify-content: center;">
							<el-image style="width: 30px;height: 30px;" :src="require('@/assets/img/icon/changePassword.png')"></el-image>
						</div>
						<div style="padding-top: 10px;text-align: center;" @click="jump(3)">修改密码</div>
					</div>
					
					<div style="width: 25%;">
						<div style="display: flex;align-items: center;justify-content: center;">
							<el-image style="width: 30px;height: 30px;" :src="require('@/assets/img/icon/address2.png')"></el-image>
						</div>
						<div style="padding-top: 10px;text-align: center;" @click="jump(5)">地址管理</div>
					</div>
				</div>
				
				<div style="border-radius: 10px;background: #fff;width: 100%;margin-top: 20px;padding: 10px;box-shadow: 0 0 14px -2px rgb(97 97 97 / 20%);position: relative;" @click="jump(0)">
					<div>我的积分消费记录</div>
					<div style="position: absolute;right: 10px;top: 10px;"><a-icon type="right" /></div>
				</div>
				<div style="border-radius: 10px;background: #fff;width: 100%;margin-top: 20px;padding: 10px;box-shadow: 0 0 14px -2px rgb(97 97 97 / 20%);position: relative;" @click="jump(1)">
					<div>我的积分获取记录</div>
					<div style="position: absolute;right: 10px;top: 10px;"><a-icon type="right" /></div>
				</div>
			</div>
			
		</div>
		<applet-bottom></applet-bottom>
	</div>
</template>

<script>
	import appletBottom from '@/components/bottom/appletIndex.vue';
	export default {
		components:{
			appletBottom
		},
		data() {
			return {
				userInfo:null,
				img:process.env.VUE_APP_OSS_URL + '/home/personal.jpg',
				avatarurl:null,
			}
		},
		
		created() {
			this.userInfo = JSON.parse(sessionStorage.getItem("getUserInfo"))
			this.avatarurl=this.userInfo.avatarurl
			// console.log(this.userInfo)
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			jump:function(e){
				if(this.userInfo!==null){
					if(e==0){
						this.$router.push({
							path: '/consumptionRecords',
						})
					}else if(e==1){
						this.$router.push({
							path: '/obtainingRecords',
						})
					}else if(e==2){
						this.$router.push({
							path: '/myOrder',
						})
					}else if(e==3){
						this.$router.push({
							path: '/changePassword',
						})
					}else if(e==4){
						this.$router.push({
							path: '/information',
						})
					}else if(e==5){
						this.$router.push({
							path: '/address',
						})
					}
				}else{
					this.$message.error("暂未登录，请先进行登录")
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>